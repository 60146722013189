<template>
	<div>
        <el-table
            :data="tableTate"
            border>
            <el-table-column
                prop="name"
                align="center"
                label="币种名称">
            </el-table-column>
            <el-table-column
                prop="currency"
                align="center"
                label="币种简称">
            </el-table-column>
            <el-table-column
                prop="ex_rate"
                align="center"
                label="汇率(1USDT兑换的比率)">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" placement="top">
                        <div slot="content">
                            1 usdt = {{scope.row.ex_rate}} {{scope.row.name}}
                        </div>
                        <span>{{scope.row.ex_rate}}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
	</div>
</template>
<script>
	export default {
		data() {
			return {
                tableTate:[],
                timer:null
			}
        },
        beforeDestroy() {
            //清除定时器
            clearInterval(this.timer);
        },
		created() {
            this.commonTotal()
            this.timer = setInterval(()=>{
                this.commonTotal()
            },1000)
		},
		methods: {
			commonTotal() {
				this.$api.exchangeRate({}).then(res => {
					if (res.status == 1) {
						this.tableTate = res.data
					}
				})
			}
		}
	}
</script>